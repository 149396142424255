@import url("https://fonts.googleapis.com/css2?family=Kavivanar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

.bg-image {
  background: -webkit-linear-gradient(
    135deg,
    rgb(0, 255, 164),
    rgb(166, 104, 255)
  );
}

.bg-text {
  background: -webkit-linear-gradient(
      135deg,
      rgb(0, 255, 164),
      rgb(166, 104, 255)
    )
    padding-box text;
  -webkit-text-fill-color: transparent;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
